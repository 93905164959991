.version-image-wrapper {
  position: relative;
  width: 100%;
  transition:
    transform 0.2s ease,
    margin 0.2s ease;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  .layer-visibility-toggle {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .visibility-toggle {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
        filter: invert(0.7);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .layer-controls {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    z-index: 1;

    .visibility-toggle {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
        filter: invert(1);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &:hover {
    .layer-controls {
      display: flex;
    }

    .layer-visibility-toggle {
      display: flex; /* Show on hover */
    }
  }
}

.version-image-wrapper.dragging {
  opacity: 0.7;
  transform: scale(0.98);
  z-index: 1000;
}

.version-image-wrapper.drag-over {
  position: relative;
}

.version-image-wrapper.drag-over-above {
  margin-top: 28px;
}

.version-image-wrapper.drag-over-above:before {
  content: "";
  position: absolute;
  top: -14px;
  height: 60px;
  width: calc(100% - 4px);
  left: 2px;
  // .version-image{
  background-color: rgba(33, 150, 243, 0.15);
  border: 2px dashed #2196f3;
  border-radius: 4px;
  // }
  z-index: 2;
}

.version-image-wrapper.drag-over-below {
  margin-bottom: 28px;
}

.version-image-wrapper.drag-over-below:before {
  content: "";
  position: absolute;
  bottom: -14px;
  height: 60px;
  width: calc(100% - 4px);
  left: 2px;
  // .version-image{
  background-color: rgba(33, 150, 243, 0.15);
  border: 2px dashed #2196f3;
  border-radius: 4px;
  // }
  z-index: 2;
}

.version-image-container {
  padding: 10px;
  min-height: 100px;
  overflow-y: auto;
}

.version-image {
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 28px;
}

.version-image:hover {
  transform: translateX(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.object-fit-contain {
  object-fit: contain;
}

.top-drop-zone {
  height: 0;
  width: 100%;
  transition: height 0.2s ease;
  position: relative;
}

.top-drop-zone.active {
  height: 60px;
  margin-bottom: 10px;
  position: relative;
}

.top-drop-zone.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 2px;
  right: 2px;
  height: 60px;
  background-color: rgba(33, 150, 243, 0.15);
  // border: 2px dashed #2196F3;
  border-radius: 4px;
  z-index: 2;
}
