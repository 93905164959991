.custom-toast {
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 357px !important;
  height: 56px !important;
  padding: 0 10px;
}

/* Common body styling */
.custom-toast-body {
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  width: 232px;
  height: 56px;
}

/* Toast Icon */
.toast-icon {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Close Button */
.toast-close {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toast-close img {
  width: 12px;
  height: 12px;
}

/* Success Toast */
.success {
  background-color: #058748 !important;
}

/* Error Toast */
.error {
  background-color: #a01200 !important;
}

/* Warning Toast */
.warning {
  background-color: #944e00 !important;
}

/* Get Help Toast */
.get-help {
  background-color: #007bff !important; /* Default blue, change if needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Help Link */
.get-help-link {
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  margin-left: 8px;
}
