.container {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.headingContainer {
  padding-top: 25px;
  width: 90%;
  margin: auto;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
}

.tabButton {
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  border-radius: 0px !important;
  font-weight: 500;
  border-radius: 4px;
}

.active {
  color: #312eff !important;
  border-bottom: 2px solid #312eff;
}

.navHeader {
  border-bottom: 1px solid #e6e6e6;
}

.settingsBody {
  height: 1px;
  flex-grow: 1;
  overflow: auto;
}
