/* .konva-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 2px 28px 2px 10px;
    background: #FEDD9B;
    border-radius: 13px;
} */

.konva-select div {
  background: #fedd9b;
  padding: 2px 28px 2px 10px;
  border-radius: 13px;
}

.konva-select div:focus {
  background: #fedd9b;
  padding: 2px 28px 2px 10px;
  border-radius: 13px;
}

/* Success state */
.approved {
  background: #d1f2d1;
}

/* Failed state */
.returned {
  background: #ffe0e0;
}

.draft {
  background: #e6e6e6;
}

/* .modal{
    z-index: 99999 !important;
} */
