// // // .swal2-icon {
// // //     font-size: 12px !important;
// // //     display: none !important;
// // //     /* height: 50px !important;
// // //       width: 50px !important; */
// // // }

// // // .swal2-title {
// // //     font-size: 25px;
// // //     padding: 5px 0px 0px 0px !important;
// // //     color: white !important;
// // // }

// // // .swal2-styled {
// // //     padding: 4px 12px 4px 12px !important;
// // // }

// // // .swal2-container {
// // //     min-width: 25% !important;
// // // }

// // // .swal2-popup {
// // //     background-color: gray !important;
// // //     height: 50px !important;
// // //     display: flex !important;
// // //     align-items: center !important;
// // // }

.swal2-icon {
  font-size: 10px !important;
  /* height: 50px !important;
      width: 50px !important; */
}

// // // .swal2-title {
// // //     font-size: 25px;
// // //     padding: 5px 0px 0px 0px !important;

// // // }

.swal2-title {
  width: 600px !important;
  height: 72px !important;
  font-size: 20px !important;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 700 !important;
  text-align: left !important;
  color: #000000 !important;
  padding: 24px 24px 16px 24px !important; /* top-right-bottom-left */
  gap: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important; /* Align text to the left */
}

.swal2-styled {
  padding: 4px 12px 4px 12px !important;
}

// // // .swal2-container {
// // //     min-width: 25% !important;
// // // }

// // .custom-swal-description {
// //     width: 600px !important;
// //     height: 72px !important;
// //     font-size: 16px !important;
// //     font-family: 'Noto Sans', sans-serif !important;
// //     font-weight: 400 !important;
// //     text-align: left !important;
// //     color: #555 !important;
// //     line-height: 24px !important;
// //     padding: 8px 24px 16px 24px !important; /* top-right-bottom-left */
// //     gap: 16px !important;
// //     display: flex !important;
// //     align-items: center !important;
// //     justify-content: flex-start !important; /* Align text to the left */
// //     white-space: normal !important;
// // }
.custom-swal-description {
  width: 600px !important;
  height: 72px !important;
  font-size: 16px !important;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400 !important;
  text-align: left !important;
  color: #555 !important;
  line-height: 24px !important;
  padding: 8px 24px 16px 24px !important; /* top-right-bottom-left */
  gap: 16px !important;
  display: flex !important;
  align-items: left !important;
  justify-content: flex-start !important; /* Align text to the left */
  white-space: normal !important;
}
