@import "../../../assets/scss/variable.scss";

.filter-container {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 576px) {
    padding: 10px;
  }
}

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.main {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-hero-section {
  height: calc(100vh - (-170px));
  overflow: hidden !important;

  @media (max-width: 768px) {
    height: calc(100vh - 190px);
  }
}

.search-hero-section2 {
  height: calc(100vh - 250px);
  overflow: hidden !important;

  @media (max-width: 768px) {
    height: calc(100vh - 190px);
  }
}

.search-bar {
  width: 100%;
  margin: 20px;
  max-width: 900px;
  margin-bottom: 20px;

  &-inner {
    margin: 0px;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      margin-top: 6px;
    }
  }
}

.search-input {
  flex-grow: 1;
  padding: 10px 10px;
  border: 1px solid #3182ce;
  border-radius: 25px 0 0 25px;
  font-size: 16px;
  outline: none;

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.search-button {
  padding: 0px 20px;
  background-color: #3182ce;
  border: 1px solid #3182ce;
  border-radius: 0 25px 25px 0;
  height: 46px !important;

  @media (max-width: 768px) {
    height: 33px !important;
    padding: 0 15px;
  }
}

.filter-box {
  width: 100%;
  max-width: 1000px;
  border: 1px solid #3182ce;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  color: #707070;
  background-color: #ffffff;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-group h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.filter-group label {
  font-size: 14px;
  font-weight: 500;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
}

.filter-option {
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.filter-option input {
  margin-right: 5px;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button {
  // width: 10vw;
  position: relative;
  padding: 10px 70px;
  background-color: #3182ce;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  // display: flex;
  // justify-content:space-around;
}

@media (max-width: 400px) {
  .filter-option {
    flex: 1 1 100%;
  }
}

.sidebar {
  // position: fixed;
  height: calc(100vh - 80px);
  min-width: 260px;
  min-height: calc(100vh - 80px);
  // box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  z-index: 60;
  transition: 0.5s;
  overflow-y: auto;

  @media (max-width: 992px) {
    position: fixed;
    top: 0px;
    left: 0;
    height: 100vh;
    z-index: 99999;
  }
}

.sidebar.active {
  left: 0;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 15px;
  min-height: 540px;

  // max-height: calc(100vh - 67px);
  // overflow-x: hidden;
  @media (max-width: 992px) {
    min-height: 620px;
  }

  @media (max-width: 670px) {
    min-height: 650px;
  }
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 8px;
}

.sd-link {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 6px 16px;
  font-size: 16px;
  flex-direction: row;
  color: #ffffff;
  background-color: #4d4d4d;
  cursor: pointer;
  text-decoration: none;
}

.sd-link p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 5px;
}

label {
  margin-left: 7px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
}

.height-100 {
  height: calc(100vh - 80px);
}

.width-100 {
  width: 100%;

  @media (min-width: 992px) {
    width: calc(100% - 250px);
  }
}

.min-w-127 {
  min-width: 127px;
}

.styled-checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #4b4b4b;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.styled-checkbox:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #4b4b4b;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-gray {
  color: #4b4b4b;
  font-family: Lato;
}

.input-border-gray {
  border: 1px solid #4b4b4b;
}

.fs-35 {
  font-size: 35px;
}

.max-w-900 {
  max-width: 900px;
}

.max-w-100 {
  max-width: 100px;
}

.p-inputswitch {
  height: 24px;
}

// card css
.max-w-300 {
  max-width: 300px;
}

.max-w-700 {
  max-width: 700px;
}

.card {
  max-height: 535px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px;
  margin-top: 10px;
  box-shadow: 0px 0px 10px 0px #3333331a;
  &.css-1hkgpcv {
    width: 100% !important;
    padding: 2px;
    max-height: 170px;
  }

  .card_body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-wrap: wrap;
    padding: 0 10px;
  }
}

.line {
  border: 1px dotted #707070;
  color: #707070;
  background-color: transparent;
  margin: 0px !important;
  width: 90%;
}

.border-grey {
  border: 1px solid #707070;
}

.detailsbtn {
  display: flex;
  justify-content: center;
  // background-color: #000000;
  background-color: #3182ce;
  color: #ffffff;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 80%;
  align-self: center;
}

.search-images {
  // height: auto;
  // max-height: 275px;
  // max-width: 280px;
  // width: auto;
  // object-fit: contain;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

// dropdown
/* src/DualDropdownComponent.css */
.dual-dropdown-container {
  display: flex;
  gap: 10px;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.dropdown-button.selected {
  background-color: #eee;
}

.dropdown-select {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
  background-color: white;
  width: 100%;
}

// dropdown end
// .filter-tags {
//   border: 1px solid #4B4B4B;
//   width: 24%;
//   border-radius: 5px;
//   justify-content: center;
// }
// .filter-label {
//   font-size: 14px;
//   color: #707070;
//   font-weight: bold;
//   white-space: nowrap;
//   min-width: 111px;
// }
// .bottom-border {
//   border: 1px dotted #B1B1B1;
//   width: 100%;
// }
.searchHeaderBg {
  background-image: url("../../../assets/icons/searchbg.svg");
  max-height: 350px;
  width: 100%;
  // overflow: hidden;
}

.MuiButtonBase-root {
  // border: 1px solid #4B4B4B !important;
  // background-color: white !important;
  border-radius: 8px;
  font-size: 12px;
}

.search-bar2 {
  position: sticky;
  top: 0px;
  z-index: 10;
  max-width: 66%;
  margin-top: -125px;

  @media (max-width: 576px) {
    max-width: 100%;
  }

  @media (max-width: 400px) {
    // margin-top: -93px;
  }
}

.search-bar-width {
  max-width: 102% !important;
  width: 102%;

  @media (max-width: 630px) {
    max-width: 107% !important;
    width: 107%;
  }

  @media (max-width: 400px) {
    max-width: 110% !important;
    width: 115%;
  }

  .text-dynamic {
    color: black;
  }
}

.text-dynamic {
  color: black;
  // color: white;
}

.bg-light-blue {
  background-color: $bg-light-blue;
}

.fs-12 {
  font-size: 12px;
}

.max-w-156 {
  max-width: 156px;
}

.text-light-blue {
  color: $bg-light-blue;
}

.border-light-blue {
  border: 1px solid $bg-light-blue;
}

.checked {
  background-color: #4b4b4b;
  color: #ffffff;
}

.show-more {
  color: #007bff;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
}

// .see-more-button {
//   background: none;
//   border: none;
//   color: #007BFF;
//   cursor: pointer;
//   font-size: 14px;
//   font-weight: bold;
//   padding: 0;
//   margin-left: 10px;
// }
/* General styles for buttons and container */
.filter-label {
  cursor: pointer;
  font-size: 14px;
  color: #707070;
  font-weight: bold;
  white-space: nowrap;
  min-width: 220px;
}

.filter-tags {
  border: 1px solid #4b4b4b;
  flex: 1 1 24%;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  margin-bottom: 8px;
}

.checked {
  background-color: #4b4b4b;
  color: #ffffff;
}

.bottom-border {
  border: 1px dotted #b1b1b1;
  width: 100%;
  margin-top: 10px;
}

.see-more-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin-left: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-tags {
    flex: 1 1 48%;
  }

  .see-more-button {
    font-size: 12px;
  }
}

@media (max-width: 1010px) {
  .filter-label {
    font-size: 12px;
    min-width: auto;
  }

  .filter-tags {
    flex: 1 1 100%;
    font-size: 12px;
    padding: 3px 8px;
  }

  .see-more-button {
    font-size: 12px;
  }

  /* On very small screens, ensure buttons go below their title */
  .category-container {
    flex-direction: column;
  }

  .category-options {
    margin-top: 8px;
  }

  // .model-container {
  //   flex-direction: column;
  // }
  .model-options {
    margin-top: 8px;
  }
}

@media (max-width: 1010px) {
  .model-container {
    flex-direction: column;
  }
}

.ctr-label {
  cursor: pointer;
  font-size: 14px;
  color: #707070;
  font-weight: bold;
  white-space: nowrap;
  // min-width: 100px;
}

.sidebar-toggle {
  left: 10px;
  top: 90px;

  @media (max-width: 768px) {
    top: 200px;
  }
}

.backbtn {
  border-radius: 0.5rem;
  background-color: white;
  // left: 50px;
  top: 90px;
  // @media (max-width: 400px) {
  //   position: fixed;
  //   top: 193px;
  //   // left: 60px;
  // }
}

.flex-container {
  justify-content: end;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}

.sd-header-container {
  background-color: #fff;
  padding-bottom: 15px;
}

.header-position {
  background-color: #f8f8f8;
  height: 290px;

  @media (min-width: 768px) {
    position: sticky;
    top: 0px;
    overflow: hidden;
    z-index: 10;
  }
}

.serch-icon-size {
  width: 28px;

  @media (max-width: 768px) {
    width: 20px;
  }
}

.max-w-100 {
  max-width: 70%;

  @media (max-width: 400px) {
    max-width: 100%;
  }
}

.max-w-70 {
  max-width: 70%;
}

.ctrwidth {
  max-width: 100px;
}

.text-truncate-3 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
