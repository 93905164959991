@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.homePageBg-login {
  height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
  background-color: #f8f8f8;
  // height: 90vh;
  // @media (max-height: 768px) {
  //   height: 100%;
  // }
}

.text-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0;
  padding-bottom: 0;
  color: #2a2a2a;
  text-align: left;
}

.subtext {
  font-size: 1.2vw;
  font-family: Lato;
  font-weight: 500;
  text-align: left;
  color: #ada7a7;
}
.mico-subtext {
  font-family: Lato;
  font-size: 1.5vw;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-align: left;
  color: #ada7a7;
}
.login-btn {
  padding: 0.2vw 2vw;
  font-size: 1.5vw;
  background-color: #3182ce;
  border: none;
  border-radius: 0.5vw;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #276bb5;
  color: white;
}
