.result-batch {
  width: 100%;
  margin-bottom: 0px;
  display: contents;
  min-height: 150px;

  .batch-grid {
    display: grid;
    gap: 0.5rem;

    &.column4 {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
      &.column4 {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (max-width: 480px) {
      &.column4 {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &.column5 {
      grid-template-columns: repeat(5, 1fr);
    }

    &.column6 {
      grid-template-columns: repeat(6, 1fr);
    }

    &.column7 {
      grid-template-columns: repeat(7, 1fr);
    }

    &.column8 {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .batch-details {
    width: 100%;
    margin-top: 20px;
    grid-column: 1 / -1;
    overflow: hidden;

    &.details-enter {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px);
    }

    &.details-enter-active {
      max-height: 2000px; // Adjust based on your content
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-in-out;
    }

    &.details-exit {
      max-height: 2000px;
      opacity: 1;
      transform: translateY(0);
    }

    &.details-exit-active {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px);
      transition: all 0.3s ease-in-out;
    }

    .detailsPage {
      animation: slideIn 0.3s ease-in-out;

      .detailMain {
        animation: fadeIn 0.3s ease-in-out 0.1s both;
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// ...existing styles...

.similar-images-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e1e1e1;

  h6 {
    color: #707070;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .slider-container {
    position: relative;
    padding: 0 40px;

    .swiper-slide {
      height: 150px;
    }

    .similar-image {
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      height: 100%;

      &.active {
        border: 2px solid #0096ff;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .ctr-tag {
        position: absolute;
        bottom: 8px;
        right: 8px;
        background: rgba(0, 0, 0, 0.6);
        padding: 4px 8px;
        border-radius: 12px;

        p {
          color: white;
          margin: 0;
          font-size: 12px;
        }
      }
    }

    .custom-prev,
    .custom-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: white;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;

      &:hover {
        background: #f5f5f5;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .custom-prev {
      left: 0;
    }

    .custom-next {
      right: 0;
    }
  }
}

.detailImg {
  position: relative;

  .similar-slider {
    margin-top: 10px;
    position: relative;
    padding: 0 25px;

    .swiper-slide {
      height: 80px;
    }

    .similar-image {
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      height: 100%;
      border: 2px solid transparent;
      transition: all 0.2s ease;

      &.active {
        border-color: #0096ff;
      }

      &:hover {
        transform: translateY(-2px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .ctr-tag {
        position: absolute;
        bottom: 4px;
        right: 4px;
        background: rgba(0, 0, 0, 0.6);
        padding: 2px 6px;
        border-radius: 8px;
        font-size: 10px;

        p {
          color: white;
          margin: 0;
        }
      }
    }

    .custom-prev,
    .custom-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: white;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      padding: 0;

      &:hover {
        background: #f5f5f5;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

    .custom-prev {
      left: 0;
    }

    .custom-next {
      right: 0;
    }
  }
}

.counterWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    p {
      min-width: 20px;
      text-align: center;
    }
  }

  img {
    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  a {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.loader-batch {
  width: 100%;
  position: relative;
  min-height: 200px;
  margin-top: 20px;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  .resultBoxWrapper {
    opacity: 0.7;
    animation: pulse 1.5s infinite;
  }
}

.no-data-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 240px);

  svg {
    max-width: 330px;
  }

  p {
    color: #282829;
    margin: 0;
    font-family: Noto Sans;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.smilierImagesWrapper {
  position: relative;
  display: block;
  width: 100%;
  margin: 30px 0;

  h5 {
    font-family: Noto Sans;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0%;
    color: #282829;

    @media screen and (max-width: 1500px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.imgSliderWrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  overflow: auto;

  .Item {
    max-width: 169px;
    max-height: 169px;

    @media screen and (max-width: 1500px) {
      max-width: 100px;
      max-height: 100px;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.7;
  }
}

.similargrpImg {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 0 25px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;

    li {
      max-width: 65px;
      cursor: pointer;
    }
  }

  .similar-thumb-slider {
    .swiper-slide {
      width: auto;
      height: 65px;
    }

    .thumb-item {
      width: 65px;
      height: 65px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid transparent;
      transition: all 0.2s ease;

      &.active {
        border-color: #0096ff;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-inline: 10px;
      }

      &:hover {
        transform: translateY(-2px);
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 24px;
      height: 24px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;

      &::after {
        font-size: 12px;
        color: white;
      }

      &.swiper-button-disabled {
        opacity: 0.35;
      }
    }

    .swiper-button-prev {
      left: -25px;
    }

    .swiper-button-next {
      right: -25px;
    }
  }
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: initial !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333333;
  color: #fff;
  border-radius: 50%;
  padding: 16px;
}
.project-listing .MuiAutocomplete-inputRoot {
  max-height: 130px;
  overflow: auto;
}

.similar-images-slider {
  position: relative;
  padding: 0 40px; // Make room for navigation buttons

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &::after {
      font-size: 14px;
      color: #333;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  // Hide navigation buttons when they're not needed
  &.swiper-container-single {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
