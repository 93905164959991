.magic-brush-popup {
  .modal-dialog {
    max-width: 98vw !important;
    height: 90vh !important;

    @media (max-width: 992px) {
      height: 70vh !important;
    }

    .bg-color {
      background-color: #f6f6f6;
    }

    .popup-heading {
      font-size: 20px;
      font-family: "Noto Sans";
      font-weight: 700;

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    .modal-body {
      background-color: #f6f6f6;

      .magic-brush-container2 {
        height: calc(100vh - 330px) !important;
      }

      .magic-brush-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 270px);
        // height: 720px;
        max-width: 720px;
        margin-top: -40px;

        @media (max-width: 992px) {
          height: calc(100vh - 320px);
        }

        @media (max-width: 576px) {
          margin-top: 10px;
        }

        img {
          width: 100%;
          object-fit: contain;
          height: -webkit-fill-available;
        }
      }

      .crop-container2 {
        height: calc(100vh - 450px) !important;
      }

      .crop-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 370px);
        // height: 720px;
        max-width: 520px;
        margin-top: 20px;

        @media (max-width: 992px) {
          height: calc(100vh - 320px);
        }

        @media (max-width: 576px) {
          margin-top: 10px;
        }

        img {
          width: 100%;
          object-fit: contain;
          height: -webkit-fill-available;
        }
      }

      .magic-brush-zoom {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2392156863);
        border: 1px solid rgba(0, 0, 0, 0.1176470588);
        width: 48px;
        border-radius: 8px;
        position: absolute;
        left: 10px;
        z-index: 2;

        @media (max-width: 992px) {
          left: 0px;
        }

        @media (max-width: 768px) {
          transform: rotate(271deg);
          left: 50px;
          bottom: -20px;
          width: 34px;

          .rotete-image {
            transform: rotate(90deg);
          }
        }
      }

      .brush-size-wrapper {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2392156863);
        border: 1px solid rgba(0, 0, 0, 0.1176470588);
        border-radius: 16px;
        background-color: white;
        width: auto;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 2;
      }
    }
  }
}

.popup-sidebar-width {
  max-width: 450px;
  width: 100%;

  @media (max-width: 1080px) {
    max-width: 300px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .choose-image-container2 {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    .choose-image {
      max-width: 73px;
      height: 62px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      img {
        width: 100%;
        height: auto;
      }

      &:first-child {
        border: 3px solid black;
      }
    }

    span {
      font-size: 10px;
      font-weight: 600;
      text-align: center;
      display: block;
    }
  }
}

.genrate-image-wrapper {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .genrate-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .genrate-image-container {
    display: flex;
    border: 1px dashed #000;
    height: 60vh;
    width: 30vw;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
      height: 55vh;
      width: 35vw;
    }

    @media (max-width: 1200px) {
      height: 50vh;
      width: 45vw;
    }

    @media (max-width: 992px) {
      height: 45vh;
      width: 50vw;
    }

    @media (max-width: 768px) {
      height: 400px;
      width: 400px;
    }

    @media (max-width: 576px) {
      height: 265px;
      width: 265px;
    }

    span {
      font-size: 16px;
      text-align: center;
      max-width: 400px;
      padding: 0 30px;
      color: #666666;

      @media (max-width: 576px) {
        padding: 0 10px;
        font-size: 12px;
      }
    }
  }

  .padding {
    padding-left: 80px;

    @media (max-width: 992px) {
      padding-left: 20px;
    }
  }
}

.casual-image-container2 {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px 10px 30px 80px;

  @media (max-width: 992px) {
    padding: 10px 10px 30px 20px;
    gap: 10px;
  }

  .heart-icon {
    position: absolute;
    right: 5px;
    top: 5px;

    @media (max-width: 992px) {
      max-width: 16px;
    }
  }

  .image-checkbox {
    width: 16px;
    height: 16px;
    background-size: cover;
    left: 5px !important;
    top: 5px !important;
  }

  .export-images {
    height: 95px;
    width: 95px;

    img {
      height: -webkit-fill-available;
      width: 100%;
    }

    @media (max-width: 992px) {
      height: 75px;
      width: 75px;
    }
  }

  .image-container-ref {
    border-radius: 15px !important;
  }
}

.smart-edit-wrapper {
  position: absolute;
  top: 8vh;
}

.smart-edit-button {
  img {
    width: 18px !important;
    height: 18px !important;
  }
}

.smart-edit-input {
  border: 1px solid rgba(204, 204, 204, 1);
  padding: 5px 10px;
  border-radius: 8px;
}
