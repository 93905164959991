.optionImg {
  position: relative;
  cursor: pointer;
}

.optionImg:hover {
  background-color: #666;
  border-radius: 10000px;
  padding: 4px;
}

.tableUserCell {
  display: flex;
  flex-direction: column;
}

.tableUserEmail {
  color: #666;
}

.tableUserName {
  font-weight: 600;
}

.tableHeadCellItem {
  font-weight: 600;
  color: #666;
}

.roleField {
  text-transform: capitalize;
}

.rightArrow {
  transform: rotate(180deg);
}

.paginationDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalUsers {
}

.arrowContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrowContainer img {
  cursor: pointer;
}
