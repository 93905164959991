:root {
  --text-black: #000000;
  --bg-light-gray: #f6f6f6;
  --border-gray: #666666;
  --color-white: #ffffff;
  --bg-color-gray: #cccccc;
  --border-color: #b3b3b3;

  --fs-16: 16px;
  --fs-20: 20px;
  --fs-14: 14px;
  --fs-12: 12px;
  --fs-24: 24px;
}

.container-fluid {
  padding: 0 40px;
}
