@import "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap";

.mention-alert-dialog {
  .MuiPaper-root {
    max-width: 400px !important;
  }

  .MuiButtonBase-root {
    border-radius: 50px !important;
    max-width: 90px !important;
    width: 100% !important;
    font-family: "Noto Sans";
    text-transform: capitalize !important;
    border: none !important;

    &:first-child {
      background-color: white !important;
      border: 1px solid black !important;
      color: black !important;
      // &:hover {
      //     border: 1px solid #ffffff00 !important;
      //     background-color: #000000 !important;
      //     color: #ffffff !important;

      // }
    }

    &:last-child {
      background-color: #312eff !important;

      // &:hover {
      //     background-color: rgb(255, 255, 255) !important;
      //     border: 1px solid black !important;
      //     color: black !important;

      // }
    }
  }

  .MuiTypography-root {
    font-family: "Noto Sans";
  }
}

.hover-btn {
  padding: 5px 10px;

  border: none;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s ease;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 10px;
}

.hover-btn:hover {
  background-color: rgb(26, 26, 26);
}

// space chat window

.chatebox_open {
  // .chatbox-space {
  //     display: none !important;
  // }

  .chat-padding {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .chat-replay {
    // display: none !important;
  }

  .chat-replay-wrap {
    // flex-direction: row-reverse;
    // justify-content: end;
  }

  .chat-replay-content {
    background-color: #f6f6f6;
    padding: 5px;
    margin-left: 40px;
    border-left: 2px solid black;
  }

  .border-bottom {
    border-bottom: none !important;
  }

  .chat-border-none {
    height: 0px !important;
  }

  .chart-border-bottom {
    // border-bottom: 1px solid #dee2e6;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 40px;
  }

  .select-design {
    display: block !important;
  }
}

.comment-content {
  // height: 67vh;
  overflow: auto;
}

.select-design {
  display: none;
}

.reject-button:hover {
  background-color: #e74c3c; /* Red for rejection */
  color: #fff;
}

.confirm-button:hover {
  background-color: #27ae60; /* Green for confirmation */
  color: #fff;
}
