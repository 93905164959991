.page-link {
  color: black;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: black;
  border-color: black;
}
