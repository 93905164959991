@import "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap";
@import "././views/Manager/component/variables.scss";

body {
  font-family: "Noto Sans" !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cursor_pointer {
  cursor: pointer;
}

.checkbox_color .form-check-input:checked {
  background-color: var(--text-black);
  border-color: var(--text-black);
}

.radio_color .form-check-input:checked {
  background-color: var(--text-black) !important;
  border: var(--text-black) !important;
}

.form-check-input:focus {
  box-shadow: none;
}

input[type="radio"] {
  accent-color: #808080;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 16px;
  border: none;
}

body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;

  &.modal-open {
    overflow-y: hidden !important;
  }
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.cancel_modal_footer {
  max-width: fit-content 92px !important;
  min-width: fit-content 92px !important;
  width: fit-content;
  background-color: #ffffff;
  padding: 8px 8px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
  border: none;
  border-radius: 40px;
  white-space: nowrap;
}

.send_modal_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto; /* Allows button width to adjust dynamically */
  padding: 8px 16px; /* Adjust padding for a balanced size */
  font-size: 14px; /* Ensures text fits properly */
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap; /* Prevents text from wrapping */
  background-color: #312eff !important;
  margin-top: 7px !important;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  /* width: 112px !important; */
}

.send_modal_footer:hover {
  background-color: #6e82ff; /* Changes background color on hover */
  color: white; /* Keeps text white */
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-block: auto;
  margin-left: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.progress-line {
  margin-inline: auto;
  margin-bottom: 15px;
  width: 90%;
  position: relative;
  height: 2px;
}

.timeline_date_picker .react-datepicker__navigation:hover *::before {
  border-color: #6b6b6b;
}

.emoji-container {
  position: absolute;
  bottom: calc(100% + 16px);
  right: 0px;
  /* left: 50%;
  transform: translateX(-50%); */
}

.emoji-container .epr-main {
  --epr-emoji-padding: 4px;
  --epr-emoji-size: 20px;
  --epr-header-padding: 5px 7.5px;
  --epr-category-navigation-button-size: 20px;
}

.popup-overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
}

.emoji-button {
  background: transparent;
  border: none;
}

.comment-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comment-input-container {
  width: 100%;
  flex-grow: 1;
}

.comment-option-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

#excalidraw-container .selected-shape-actions .Island.App-menu__left {
  right: 0;
  top: 44px;
}

.replyy {
  flex-grow: 1;
}

.edit-emoji-container .epr-main {
  --epr-preview-height: 40px;
  z-index: 10;
}

.custom-resizer {
  width: 8px;
  /* Adjust thickness */
  background: #e0e0e0;
  /* Light grey */
  cursor: col-resize;
  /* Cursor for resizing */
  position: relative;
}

.custom-resizer:hover {
  background: #d0d0d0;
  /* Darker on hover */
}

/* Handle styling for a better drag UI */
.custom-resizer::before {
  content: "";
  background-image: url("./assets/drag_handle.svg");
  width: 100%;
  height: 17px;
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.vs-hamburger-item {
  border: 2px solid transparent;
}

.vs-hamburger-item:hover {
  border: 2px solid #312eff;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dual-mode-excalidraw .App-menu_top,
.dual-mode-excalidraw .App-toolbar-content {
  display: none !important;
}

.cursor-default {
  cursor: default;
}

/* .react-datepicker__day.highlight-after {
  background-color: #ffcc80 !important; 
  color: #000 !important;
  border-radius: 50%;
} */
