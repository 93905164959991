.buttonsContainer {
  justify-content: flex-end;
  gap: 10px;
}

.imageInfo {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  flex-grow: 1;
}

.dimensions {
  margin: 0;
}

.info {
  flex-grow: 1;
}

.itemContainer {
  gap: 0.5rem;
  flex-grow: 1;
  margin-right: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  max-height: 95vh;
}

.imagesContainer {
  overflow: auto;
}
