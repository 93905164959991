@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import "../scss/variable.scss";

// font size
.fs-64 {
  font-size: 64px;

  @media (max-width: 1200px) {
    font-size: 50px;
  }

  @media (max-width: 1000px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.fs-24 {
  font-size: 24px;

  @media (max-width: 640px) {
    font-size: 20px;
  }
}

.fs-14 {
  font-size: 14px;

  @media (max-width: 640px) {
    font-size: 12px;
  }
}

.fs-16 {
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
}

.fs-35 {
  font-size: 35px;

  @media (max-width: 500px) {
    font-size: 30px;
  }
}

.fs-18 {
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
}

.fs-32 {
  font-size: 32px;

  @media (max-width: 1201px) {
    font-size: 24px;
  }
}

// font weight
.text-500 {
  font-weight: 500;
}

// font family
.font-sora {
  font-family: "Sora", sans-serif !important;
}

// text color
.text-extrabluedark {
  color: $extra-blue-dark;
}

.text-gray {
  color: $color-gray;
}

// border
.input-border {
  border: 1.5px solid $border-purple;
  width: 100%;
  height: 64px;
  padding-left: 80px;
  padding-right: 10px;

  &::placeholder {
    font-size: 18px;

    @media (max-width: 1000px) {
      font-size: 15px;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 50px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 40px;
    padding-left: 60px;
  }
}

.searchinput {
  border: 1.5px solid $border-purple;
  width: 710px;
  height: 64px;
  padding-left: 80px;
  padding-right: 10px;

  &::placeholder {
    font-size: 18px;

    @media (max-width: 1000px) {
      font-size: 15px;
    }
  }

  @media (max-width: 1000px) {
    width: 350px;
    height: 50px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    height: 40px !important;
    padding-left: 60px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.border-purple {
  border: 1px solid #3182ce;
}

.border-blue {
  border: 1px solid $border-blue !important;
}

.border-extrabluedark {
  border: 1px solid $extra-blue-dark;
}

.border-light-purple {
  border: 1px solid $border-light-purple !important;
}

.rounded-20 {
  border-radius: 20px;
}

// background color
.purple-gradiant {
  background-image: linear-gradient(to right, #9a72ff, #6257ff);

  span {
    color: white !important;
  }
}

.gradiant-button {
  background-image: linear-gradient(to right, #9a72ff, #6257ff);
  font-size: 18px;
  font-weight: 600;
  max-width: 280px;
  width: 100%;
  height: 64px;

  @media (max-width: 1000px) {
    max-width: 230px;
    height: 50px;
  }
}

// background image
.homePageBg {
  // background-image: url("../images/homePageBg.png");
  background-size: 100% 100%;
  background-color: #f8f8f8;
  height: 90vh;

  @media (max-height: 768px) {
    height: 100%;
  }
}

.w-calc-100 {
  width: calc(100% - 250px);

  @media (max-width: 992px) {
    width: 100%;
  }
}

.bg-purple {
  background-image: url("../images/bg-purple.png");
  background-size: 100% 100%;
}

.bg-white-gray {
  background-color: $text-white-gray;
}

// width
.w-220 {
  width: 220px;

  @media (max-width: 1000px) {
    width: 150px;
  }
}

.w-fit-content {
  width: fit-content;
}

.max-w-390 {
  max-width: 380px;
}

// all css
.search-icon {
  top: 20px;
  left: 25px;
  border-right: 1px solid $border-purple;
  padding-right: 15px;

  @media (max-width: 1000px) {
    top: 13px;
    left: 25px;
  }

  @media (max-width: 768px) {
    top: 8px;
    left: 15px;
    padding-right: 10px;
  }
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
}

.img-blur {
  cursor: pointer;

  &:hover {
    img {
      filter: blur(3px);
      transition: all ease 0.6s;
    }

    .hover-display {
      display: block;
    }
  }

  .hover-display {
    display: none;
  }
}

.searchImagesScroll {
  overflow-y: auto;
}

.hover-button {
  top: 35%;
}

.dropdown-toggle {
  &::after {
    margin-left: 20px !important;
    font-size: 20px;
    margin-bottom: -5px;

    @media (max-width: 576px) {
      margin-left: 2px !important;
      font-size: 15px;
      margin-bottom: -1px;
    }
  }
}

.dropdown-toggle2 {
  &::after {
    margin-left: 20px !important;
    font-size: 20px;
    margin-bottom: -5px;

    @media (max-width: 576px) {
      margin-left: 25px !important;
      font-size: 20px !important;
      margin-bottom: -1px;
    }
  }
}

// .dropdown-menu {
//   border-radius: 5px;
//   display: none;
//   // z-index: 1;
//   background-color: #ffffff;
//   border: 1px solid #3182ce;
//   margin-top: 20px !important;
//   padding: 0;
//   overflow: hidden;
//   display: block;

//   &-item {
//     height: 35px;
//   }
// }

.sitemenu {
  z-index: 9999;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: auto;
  max-height: 200px;
  border: 1px solid #3182ce;
  border-radius: 5px;
}

.sitemenu::-webkit-scrollbar-thumb {
  background: #d9d9d9 !important;
}

.sitemenu-item {
  cursor: pointer;
  padding: 5px 30px;
  color: black;
  background-color: white;
  border-bottom: 1px dashed gray;
  text-align: left !important;
  padding-bottom: 6px;
}

.Language-down {
  border: 1px solid #3182ce;
  border-radius: 10px;
  background-color: #ffffff;
  list-style-type: none;
  transform: translate(-19px, 0px);
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
}

.Language-down-item:hover {
  background-color: #e9ecef !important;
}

.Language-down-item {
  background-color: #ffffff;
  text-align: end !important;
  height: 35px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}

.Language-down-item:first-child {
  border-bottom: 1px dashed gray;
}

.sitemenu-item:hover {
  background-color: #e1e1e1;
}

.form-switch {
  background-color: $input-switch-bg;
  padding-left: 0px !important;
  border-radius: 30px;

  .form-check-input {
    background-image: url("../icons/switchRound.svg") !important;
    height: 15px;
    width: 35px;
    margin: 5px;
    background-color: transparent;
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }

    &:checked {
      background-position: right center;
      background-image: url("../icons/switchRound.svg") !important;
    }
  }
}

.tag-button {
  height: 34px;
  max-width: 300px;

  @media (max-width: 1200px) {
    height: 30px;
    max-width: fit-content;

    &__search {
      max-width: 300px;
    }
  }
}

.add-tag-button {
  width: 120px;
  height: 50px;

  @media (max-width: 1200px) {
    width: 90px;
    height: 40px;
  }
}

.description-button {
  max-width: 200px;
  height: 50px;
  width: 100%;

  @media (max-width: 1200px) {
    height: 40px;
  }
}

.description-img {
  width: 450px;

  @media (max-width: 1201px) {
    width: 350px;
  }

  @media (max-width: 500px) {
    width: 300px;
  }
}

.description {
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.description-height {
  @media (max-width: 992px) {
    height: 100%;
  }
}

.img-search-container {
  height: 275px;
  width: 280px;
}

.search-imges {
  height: auto;
  max-height: 275px;
  max-width: 280px;
  width: auto;
  object-fit: contain;
}

.prev-button {
  @media (max-width: 500px) {
    margin-top: 50px;
  }

  button {
    left: -20%;
    top: -10px;

    @media (max-width: 700px) {
      left: -15%;
      top: -10px;
    }

    @media (max-width: 500px) {
      left: 0;
      top: -50px;
    }

    img {
      @media (max-width: 1200px) {
        width: 40px;
      }
    }
  }
}

.header-wrapper {
  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.MuiInputBase-root {
  // border: none !important;
  // height: 40px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  height: 40px !important;
}

.MuiFormLabel-root {
  display: none !important;
}

.rounded-8 {
  border-radius: 8px;
}

.font-Oswald {
  font-family: "Oswald", sans-serif;
}

.text-light-gray {
  color: $text-gray;
}

.swiper {
  position: unset !important;

  .swiper-button-prev {
    left: -6px !important;

    @media (max-width: 768px) {
      left: 30px !important;
    }

    content: "";
    display: block;
    position: absolute;
    z-index: 999;
    height: 50px;
    width: 50px;
    background-size: 50px;
    background-repeat: no-repeat;
  }

  .swiper-button-next {
    right: 2px !important;

    @media (max-width: 768px) {
      right: 1px !important;
    }

    content: "";
    display: block;
    position: absolute;
    z-index: 999;
    height: 50px;
    width: 50px;
    background-size: 50px;
    background-repeat: no-repeat;
  }
}

// .swiper-wrapper{
//   justify-content: center;
// }
.swiper-button-disabled {
  display: none;
}

.slider-padding {
  padding: 15px 90px 15px 90px;

  @media (max-width: 768px) {
    padding: 15px 70px 15px 70px;
  }
}

.img-container {
  height: auto;
  width: 355px;

  img {
    height: auto;
    max-height: 452px;
    max-width: 307px;
    width: auto;
  }
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  background-color: #fff;
  z-index: 9999;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  flex-direction: column;
}

.spinner-grow {
  color: $border-purple;
}
.dropdown-item {
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px 30px;
  border-bottom: 1px dashed gray;
  transition: all 0.3s ease-in-out; /* Smooth transition */
  border: 2px solid transparent; /* Default border */
}

/* Hover effect - Highlight border */
.dropdown-item:hover {
  border: 1.5px solid #5c67c9 !important; /* Border highlight on hover */
  transform: scale(1.02); /* Slight pop effect */
}

// /* Active/Selected effect - Keeps the border highlighted */
// .dropdown-item:active,
// .dropdown-item.selected {
//   border: 2px solid #312EFF; /* Highlighted border when selected */
//   //box-shadow: 0px 4px 8px rgba(49, 46, 255, 0.2); /* Soft glow effect */
// }

.dropdown-arrow {
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: 1000px) {
    top: 12px;
    right: 20px;
  }

  @media (max-width: 768px) {
    top: 8px;
    right: 8px;
  }
}

.search-button {
  height: 64px;

  @media (max-width: 1000px) {
    height: 50px;
  }

  @media (max-width: 768px) {
    height: 40px;
  }
}

.abc {
  transform: translateY(0px);
  display: block;
  transition: all ease-in-out 0.8s;
  max-height: 100%;
}

.sda {
  transform: translateY(-200px);
  display: none;
  transition: all ease-in-out 0.8s;
  max-height: 0px;
  overflow-y: hidden;
}

.hidden {
  height: 0px;
  -webkit-transition:
    height,
    0.5s linear;
  -moz-transition:
    height,
    0.5s linear;
  -ms-transition:
    height,
    0.5s linear;
  -o-transition:
    height,
    0.5s linear;
  transition:
    height,
    0.5s linear;
}

.hidden.open {
  -webkit-transition:
    height,
    0.5s linear;
  height: 150px;
  -moz-transition:
    height,
    0.5s linear;
  -ms-transition:
    height,
    0.5s linear;
  -o-transition:
    height,
    0.5s linear;
  transition:
    height,
    0.5s linear;
}

.close-btn-hover {
  transition: all ease-in-out 0.5s;
  background-color: #8a6bff;

  &:hover {
    background-color: black;
  }
}

button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.border-extrabluedark-hover {
  border: 1px solid $extra-blue-dark;
}

.border-extrabluedark-hover:hover {
  border: 3px solid $text-light-purple;
}

.active_image {
  border: 3px solid $text-light-purple;
}

.p-tabmenu-nav {
  padding: 0px;
  display: flex;
  align-items: center;
  background-color: transparent !important;

  .p-tabmenuitem {
    .p-menuitem-link {
      padding: 0px 0px 10px 0px !important;
      width: 120px;
      display: flex;
      justify-content: center;
      background-color: transparent !important;
      text-decoration: none;

      .p-menuitem-text {
        color: #6a5bff;
      }
    }
  }
}

.ctrspan {
  font-size: 14px;
  font-weight: bold;
  border-radius: 7px;
  padding: 2px;
  z-index: 5;
  width: fit-content;
}

.mico-name {
  text-decoration: none;
  font-size: xx-large;
  font-weight: bold;
  color: #3c849b;
}

// filter
.text-primary {
  color: #5a8dee !important;
}

.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}

.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}

.navbar {
  border-radius: 5px;
}

.search-select {
  border: 1px solid #e1e1e1;
  max-width: 200px;
  height: 36px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}

.btn:hover {
  color: white;
}

.marign-left-28 {
  margin-left: -28px;
}

.remove_outline:focus {
  outline: none !important;
  border: none;
}

.remove_outline {
  border: none;
}

.max-w-400 {
  max-width: 400px;
  min-width: 180px;
}

.p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight
  .p-inputswitch-slider {
  background: #3182ce;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: #3182ce !important;
}

// micocover
.title {
  font-weight: 900;
  line-height: 0.9;
  text-align: left;
  font-family: Lato, sans-serif;
  letter-spacing: 0.01em;
  color: #112e2f;
}

// micocover end
