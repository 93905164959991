// Importing any existing styles
// @import "../../styles/variables.scss";

.detailsPage {
  margin-top: 20px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .detailMain {
    display: flex;
    flex-wrap: wrap;

    .detailImg {
      flex: 1;
      min-width: 300px;
      position: relative;

      .imgWrap {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-lazy {
          max-width: 100%;
          max-height: 600px;
          object-fit: contain;
        }

        .similargrpImg {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.5);
          padding: 10px;

          .similar-thumb-slider {
            .thumb-item {
              cursor: pointer;
              margin: 0 5px;
              border: 2px solid transparent;
              transition: all 0.3s ease;
              overflow: hidden;
              border-radius: 4px;
              height: 80px;

              &.active {
                border-color: #1976d2;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .detailContent {
      flex: 1;
      min-width: 300px;
      padding: 20px;

      .detailHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .detailTitle {
          h5 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        }

        .detailFeature {
          ul {
            display: flex;
            gap: 15px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #f0f0f0;
                transition: all 0.3s ease;

                &:hover {
                  background: #e0e0e0;
                }
              }
            }
          }
        }
      }

      .ctrBox {
        padding: 15px;
        background: #f8f8f8;
        border-radius: 8px;
        margin-bottom: 20px;

        .formate {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .formateCont {
            flex: 1;
            min-width: 100px;

            h5 {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 8px;
            }

            p {
              margin: 0;
              font-size: 14px;
            }
          }
        }

        .ai-header {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          h5 {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            margin: 0;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        .ai-cont {
          p {
            margin: 0;
            font-size: 14px;
            line-height: 1.5;
          }
        }

        .project-filter {
          .project-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            h5 {
              font-size: 16px;
              margin: 0;
            }
          }

          .project-listing {
            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                display: flex;
                align-items: center;
                width: auto;
                gap: 10px;
                margin-bottom: 10px;

                span {
                  display: none;
                }

                &:hover {
                  span {
                    display: inline-block;
                  }
                }

                a {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0%;
                  color: #282829;
                  transition: 0.5s all ease;

                  &:hover {
                    color: #312eff;
                    transition: 0.5s all ease;
                  }
                }
              }
            }

            .addTagWrapper {
              position: relative;
              display: flex;
              width: 100%;
              margin: 20px 0;
              gap: 20px;

              @media screen and (max-width: 1500px) {
                margin-bottom: 0;
              }

              .industryTagBox {
                width: 50%;

                .addTag {
                  margin-bottom: 15px;

                  @media screen and (max-width: 1500px) {
                    margin-bottom: 8px;
                  }

                  h5 {
                    color: #282829;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 0;

                    @media screen and (max-width: 1500px) {
                      font-size: 12px;
                      line-height: 18px;
                    }
                  }

                  ul {
                    li {
                      padding: 0;
                      background-color: transparent;
                      border: none;
                      color: #282829;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;

                      @media screen and (max-width: 1500px) {
                        font-size: 14px;
                        line-height: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .buttonGrp {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        margin-top: 20px;

        button {
          padding: 8px 20px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;

          &:first-child {
            background: #f0f0f0;
            border: 1px solid #ccc;

            &:hover {
              background: #e0e0e0;
            }
          }

          &:last-child {
            background: #1976d2;
            color: white;
            border: 1px solid #1976d2;

            &:hover {
              background: #1565c0;
            }
          }
        }
      }
    }
  }
}

// Standalone page styles
.standalone-detail-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      margin: 0;
    }

    .back-button {
      padding: 8px 15px;
      background: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover {
        background: #e0e0e0;
      }
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .detailMain {
    flex-direction: column;

    .detailImg {
      .imgWrap {
        .img-lazy {
          max-height: 400px;
        }
      }
    }
  }

  .standalone-detail-page {
    padding: 10px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 16px;
  color: #666;
}
