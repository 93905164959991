.gridContainer {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow: hidden;
}

.imageItem {
  height: auto;
  aspect-ratio: 1;
}

.imageItemTitle {
  display: inline-block;
  width: 100%;
}
