.w-fit-content {
  width: fit-content;
}

.font-s-18 {
  font-size: 18px;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.min-h-130 {
  min-height: 130px;
}

.font-s-12 {
  font-size: 12px;
}

.font-s-34 {
  font-size: 34px;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
}

.search-content {
  position: absolute;
  top: 170px;

  @media (max-width: 670px) {
    top: 280px;
  }
}

.editImg {
  // height: 100%;
  // width: 100%;
  // object-fit: contain;
  max-width: 150px;
  /* Adjust the value as needed */
  height: auto;
  margin: 5px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content !important;
  // flex-shrink: 100 !important;
}

// .swiper-slide {
//   flex-shrink: 100 !important;
//   width: auto !important;;
// }

.custom-prev,
.custom-next {
  background-color: #3182ced9;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
  height: 30px;
  width: 30px;
}

.custom-prev {
  margin-left: -20px;
}

.custom-next {
  margin-right: -20px;
}
