.uploadButton {
  border: none;
  font-size: 16px;
  border-color: #6e82ff;
  color: #ffffff;
  border-radius: 100px;
  background-color: #312eff;
  padding: 0.5rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 600;
}

.tabTitle {
  flex: 1;
  flex-grow: 1;
  text-align: center;
}
