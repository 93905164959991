.acronym {
  background-color: rgb(49, 46, 255);
}

.due {
  background-color: red;
}

.inputCheckbox {
}

.inputCheckbox:checked + .acronym {
  background-color: rgba(0, 128, 0);
}

/* .inputCheckbox:checked+.acronym:hover::after {
    content: "x";
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
} */

.acronym::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  pointer-events: none;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
}

.inputCheckbox:checked + .acronym:hover::after {
  content: "\292B";
}

.inputCheckbox:not(:checked) + .acronym:hover::after {
  content: "\2713";
}

.acronym:hover .initials {
  visibility: hidden;
}

.acronym:hover::after {
  /* content: ""; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressLine {
  background-color: rgb(49, 46, 255);
}

.itemContainer {
  position: absolute;
  top: 0px;
  transform: translate(-50%, -50%);
}

.itemSlot {
  margin: 0;
  cursor: pointer;
  padding: 5px;
  color: #fff;
  border-radius: 50%;
  aspect-ratio: 1;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 600;
  border: 3px solid white;
  cursor: pointer;
}

.referenceRedirectButton {
  height: fit-content;
  min-width: 170px;
  width: auto;

  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.5;
  text-align: center;

  padding-inline: 20px;
  padding-block: 6px;

  border: 2px solid #6e82ff;
  border-radius: 50px;
  color: #312eff;
  text-decoration: none;
  transition: 0.5s all ease;
  background: transparent;
  z-index: 5;

  /* position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%; */
}

.referenceRedirectButton:hover {
  background: #312eff;
  color: #fff;
  border-color: transparent;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #282829;
}
