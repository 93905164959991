@import "../component/variables.scss";
$primary: #141353;
$secondary: #312eff;
header {
  position: sticky;
  display: flex;
  width: 100%;
  height: 60px;
  z-index: 999;
  align-items: center;
  background: var(--color-white);
  top: 0;
  // @media screen and (max-width: 1500px) {
  //     height: 50px;
  // }
  .menuWrap {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    ul.menuListing {
      list-style: none;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 16px;

      li {
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.28px;

        a {
          color: #282829;
          text-decoration: none;
          transition: 0.5s all ease;
          &:hover {
            color: $secondary;
            transition: 0.5s all ease;
          }
        }
        .profile-manu {
          border-radius: 70px;
          border: 1px solid rgba(40, 40, 41, 0.2);
          background: #e6e6e6;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
  .padding {
    padding-left: 210px !important;
    color: #312eff !important;
  }
  .color {
    color: #312eff !important;
    // padding-left: 6px !important;
  }
  .notificationPadding {
    padding-left: 5px !important;
  }
  .logoWrap {
    position: relative;
    display: flex;
    width: auto;
    align-items: center;
    gap: 16px;
    a {
      padding-right: 16px;
      border-right: 1px solid rgba(40, 40, 41, 0.2);
    }
  }
  .dropdownWrap {
    display: flex;
    align-items: center;
    width: auto;
    gap: 5px;
    cursor: pointer;
    input {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 180px;
      border-radius: 8px;
      border: 1px solid $primary;
      outline: none;
      padding: 2px 5px;
    }
  }
}
.filterdropdown {
  position: relative;
  display: inline-block;
  width: auto;
  ul {
    position: absolute;
    display: block;
    width: 210px;
    max-height: 250px;
    overflow: auto;
    background: #fff;
    padding: 15px;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    box-shadow:
      0px 10px 10px -5px rgba(0, 0, 0, 0.04),
      0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 0px 1px 0px rgba(0, 0, 0, 0.24);
    li {
      display: block;
      background-color: #fff;
      padding: 6px 10px;
      border-radius: 5px;
      margin: 5px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #282829;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        background-color: #eff3ff;
        transition: all 0.5s ease;
      }
    }
  }
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 42px;
  right: 0px;
  border-radius: 16px;
  border: none;
  max-height: 310px;
  overflow: auto;
  scrollbar-width: auto;
  z-index: 1021;
}

.profile_tile {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
  padding: 2px 0;
  white-space: break-spaces;
}

.profile_tile_short_des {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-black);
  padding: 2px 0;
  white-space: break-spaces;
}
.profile_time {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 400;
  color: var(--border-gray);
  padding: 2px 0;
}
.dropdown-menu.dropdown-menu-end.profile.show {
  border-radius: 16px;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-10px, 40px) !important;
}
