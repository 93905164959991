.referenceRedirectButton {
  height: fit-content;
  min-width: 170px;
  width: auto;

  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.5;
  text-align: center;

  padding-inline: 20px;
  padding-block: 6px;

  border: 2px solid #6e82ff;
  border-radius: 50px;
  color: #312eff;
  text-decoration: none;
  transition: 0.5s all ease;
  background: transparent;
  z-index: 5;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.referenceRedirectButton:hover {
  background: #312eff;
  color: #fff;
  border-color: transparent;
}
