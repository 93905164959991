.fs-30 {
  font-size: 30px;

  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.min-h-36 {
  min-height: 36px !important;
}

.min-w-100 {
  min-width: 110px;

  @media (max-width: 400px) {
    min-width: 90px;
  }
}
