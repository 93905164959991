@import "../../assets/scss/variable.scss";

$primary: #141353;
$secondary: #312eff;
$white: #fff;

.heroBanner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../assets/images/herobanner.png");
  min-height: 200px;
  height: calc(39vh - 20px);
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1500px) {
    height: 215px;
  }

  .contentWrap {
    text-align: center;
    margin-bottom: 30px;

    @media screen and (max-width: 1500px) {
      margin-bottom: 15px;
    }

    h4 {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      color: $primary;

      @media screen and (max-width: 1500px) {
        font-size: 20px;
        line-height: 24px;
      }

      span {
        font-weight: 700;
      }
    }
  }

  .buttonWrap {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    justify-content: center;
    gap: 20px;

    a {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 240px;
      height: 64px;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      border: 3px solid $secondary;
      color: $secondary;
      text-decoration: none;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      gap: 10px;
      background-color: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(20px);

      @media screen and (max-width: 1500px) {
        font-size: 14px;
        line-height: 24px;
        max-width: 158px;
        height: 46px;
      }

      &:last-child {
        background-color: $secondary;
        color: $white;
        // max-width: 150px;
        // height: 46px;
        box-shadow:
          0px 79px 22px 0px rgba(32, 33, 143, 0),
          0px 51px 20px 0px rgba(32, 33, 143, 0.01),
          0px 29px 17px 0px rgba(32, 33, 143, 0.05),
          0px 13px 13px 0px rgba(32, 33, 143, 0.09),
          0px 3px 7px 0px rgba(32, 33, 143, 0.1);
      }
    }
  }
}

// search input box
.formWrap {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  top: -55px;

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: #141353;
    margin-bottom: 24px;

    @media screen and (max-width: 1500px) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;
    }
  }

  .searchWrapper {
    position: relative;
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 60px;

    .searchInput {
      position: relative;
      display: block;
      width: 40%;

      span {
        position: absolute;
        display: flex;
        width: auto;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 10px;
        z-index: 2;
      }

      input {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        color: #808080;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        background-color: #fff !important;
        outline: none;
        padding-left: 50px;
        font-family: Noto Sans;
        border-radius: 60px 0px 0px 60px;
        border: 2px solid rgba(40, 40, 41, 0.1);
        border-right: none;

        @media screen and (max-width: 1500px) {
          padding: 4px 20px;
          line-height: 14px;
          font-size: 12px;
          padding-left: 50px;
        }
        &::placeholder {
          color: #808080;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          @media screen and (max-width: 1500px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .industryWrap,
    .tagWrap,
    .ctrWrap {
      display: block;
      position: relative;
      width: 210px;
      // border-right: 1px solid rgba(40, 40, 41, 0.20);
      // margin-right: 15px;

      span {
        color: #282829;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }

      p {
        color: #282829;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }

      .custom-dropdown {
        position: relative;
        width: 100%;

        .dropdown-header {
          padding: 8px 12px;
          cursor: pointer;
          display: block;
          background: #fff;
          border: 2px solid rgba(40, 40, 41, 0.1);
          border-left-color: transparent;
          border-right-color: transparent;

          @media screen and (max-width: 1500px) {
            padding: 4px 12px;
          }

          &:hover,
          &.active {
            border-color: #312eff;
          }

          h5 {
            font-family: Noto Sans;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
            color: #282829;
          }

          .selected-item-wrap {
            display: flex;
            align-items: center;
            gap: 6px;
          }

          p {
            color: #282829;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 1500px) {
              font-size: 12px;
            }
          }
        }

        .options-list {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 16px;
          margin: 0;
          margin-top: 4px;
          max-height: calc(50vh - 110px);
          width: 320px;
          overflow-y: auto;
          z-index: 1000;
          padding: 0;
          list-style: none;
          box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.102);

          .indTitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            @media screen and (max-width: 1500px) {
              padding: 5px 10px;
            }

            span {
              color: #282829;
              font-family: Noto Sans;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;

              @media screen and (max-width: 1500px) {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }

          li {
            position: relative;
            padding: 8px 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #282829;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @media screen and (max-width: 1500px) {
              gap: 2px;
            }

            &[data-id="-2"] {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding-bottom: 12px;
              margin-bottom: 4px;
            }

            &.selected {
              color: #312eff;
            }

            .MuiCheckbox-root {
              visibility: hidden;
              position: absolute;
              width: 0;
              height: 0;
              margin: 0;
              padding: 0;
            }

            &::before {
              content: "";
              display: inline-block;
              width: 17px;
              height: 17px;
              border: 2px solid #808080;
              border-radius: 4px;
              margin-right: 8px;
              transition: all 0.2s ease;
              position: relative;
              top: -1px;
              background-color: white;

              @media screen and (max-width: 1500px) {
                width: 14px;
                height: 14px;
              }
            }

            &.selected::before {
              border-color: #312eff;
            }

            &.selected::after {
              content: "";
              position: absolute;
              left: 26px;
              top: 11px;
              width: 5px;
              height: 10px;
              border: solid #312eff;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              transition: all 0.2s ease;

              @media screen and (max-width: 1500px) {
                left: 25px;
                top: 9px;
                width: 4px;
              }
            }

            &[data-id="-2"] {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding-bottom: 12px;
              margin-bottom: 4px;
            }

            span.MuiCheckbox-root {
              display: none;
            }

            .value {
              color: #282829;
              font-size: 14px;
              font-weight: 400;
              line-height: normal;
              max-width: 230px;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;

              @media screen and (max-width: 1500px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .tagWrap {
      width: 180px;
    }

    .ctrWrap {
      width: 150px;
      border: none;
      cursor: pointer;
      background: #fff;
      border: 2px solid #2828291a;
      border-left-color: transparent;
      border-right-color: transparent;
      padding: 4px 20px;
    }

    .buttonWraped {
      position: absolute;
      display: flex;
      width: 100%;
      max-width: 165px;
      border-radius: 40px;
      background: #312eff;
      margin-right: 8px;
      height: 48px;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      right: 0;
      z-index: 5;
      @media screen and (max-width: 1300px) {
        position: relative;
      }

      @media screen and (max-width: 1500px) {
        max-width: 130px;
        height: 40px;
      }

      button {
        background: transparent;
        border: none;
        color: #fff;
        width: 100%;
        max-width: 117px;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
        }

        &.dropdown-toggle::after {
          margin-left: 0px !important;
        }

        &.dropdown-toggle-split {
          max-width: 44px;
          padding: 0;

          &:before {
            position: absolute;
            display: block;
            content: "";
            width: 1px;
            height: 24px;
            background-color: #6e82ff;
          }
        }
      }

      .dropdown-menu.show {
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        background: #fff;
        width: 224px;
        border-radius: 6px;
        padding: 0;
        box-shadow:
          0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 0px 1px 0px rgba(0, 0, 0, 0.24);

        button {
          display: block;
          width: 100%;
          max-width: 100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #282829;
          text-decoration: none;
          padding: 7px 4px;
          margin-left: 2px !important;
        }
      }
    }
  }
}

.tags-dropdown {
  position: relative;

  .tags-container {
    position: absolute;
    width: 320px;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.102);
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .search-header {
      padding: 8px;
      border: 2px solid #b3b3b3;
      border-radius: 8px;

      &.hightlighted {
        border-color: #312eff;
      }

      input {
        width: 100%;
        padding: 8px 12px;
        border: none;
        border-radius: 4px;
        outline: none;
        font-family: Noto Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #808080;

        @media screen and (max-width: 1500px) {
          padding: 0 12px;
          font-size: 12px;
        }
      }
    }

    .selected-chips {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .chip {
        display: flex;
        align-items: center;
        background: #eff3ff;
        border-radius: 16px;
        font-size: 14px;
        color: #312eff;
        gap: 4px;
        height: 28px;
        border: 2px solid #2828291a;
        padding-left: 8px;

        .chip-label {
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Noto Sans;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #20218f;
        }

        .chip-remove {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 2px;

          svg {
            width: 20px;
            fill: #20218f;
          }

          &:hover {
            background: rgba(0, 0, 0, 0.04);
            border-radius: 50%;
          }
        }
      }
    }

    .filtered-tags-container {
      overflow-y: auto;

      &.activeDrop {
        height: 300px;

        @media screen and (max-width: 1500px) {
          height: 210px;
        }
      }
    }
  }
}

.indDropDown {
  position: absolute;
  display: inline-block;
  // width: 100%;
  background: #fff;
  padding: 16px;
  width: 324px;
  border-radius: 16px;
  max-height: 350px;
  overflow: auto;
  box-shadow:
    0px 10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.24);
  z-index: 1;
  top: 56px;

  .title {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 0;

    h5 {
      color: #282829;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
    }

    svg {
      cursor: pointer;
    }
  }

  .content {
    margin-bottom: 15px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #282829;
      margin-bottom: 0;
    }
  }

  .inputWrapper {
    .inputWrap {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .inputbox {
        position: relative;
        display: block;
        width: 100%;

        input {
          position: relative;
          display: block;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #b3b3b3;
          border-radius: 8px;
          height: 40px;
          padding: 10px;
          outline: none;
        }

        .perIcon {
          position: absolute;
          display: flex;
          width: auto;
          right: 15px;
          top: 0;
          bottom: 0;
          align-items: center;
        }
      }
    }
  }

  .selctbox {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    color: #282829;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-break: break-all;
  }
}

.resultBox {
  min-height: 150px;
}

// search result
.search-results__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
}

.heroResult {
  background-color: #fff;
}

.stickyHeader {
  position: sticky;
  display: block;
  width: 100%;
  background-color: #fff;
  top: 60px;
  z-index: 5;
}

.searchHeader {
  position: relative;
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  .searchInput {
    width: calc(100% - 166px);

    input {
      position: relative;
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      height: 60px;
      padding: 5px 50px;
      border: none;
      outline: none;

      @media screen and (max-width: 1500px) {
        font-size: 14px;
        line-height: 18px;
        height: 45px;
      }

      &::placeholder {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;

        @media screen and (max-width: 1500px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .searcIcon {
      position: absolute;
      display: flex;
      width: auto;
      top: 0;
      bottom: 0;
      left: 10px;
      z-index: 5;
      align-items: center;
    }
  }

  .ctr-dropdown {
    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #282829;
      text-decoration: none;
    }
  }

  .counterWrapper {
    position: relative;
    display: flex;
    width: auto;
    border: 2px solid #e6e6e6;
    border-radius: 8px;
    padding: 6px;

    p {
      margin-bottom: 0;
      height: 40px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      @media screen and (max-width: 1500px) {
        width: 20px;
        height: 20px;
      }
    }

    a {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media screen and (max-width: 1500px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// search result

.main-result-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  gap: 24px;

  .filterWrap {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 394px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.102);
    padding: 18px;
    border-radius: 10px;
    max-height: calc(100vh - 220px);
    overflow: auto;

    @media screen and (max-width: 1500px) {
      max-width: 260px;
    }

    // filter card
    .filterHeader {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 5px;
      }

      h5 {
        color: #282829;
        font-family: Noto Sans;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0%;

        @media screen and (max-width: 1500px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      img {
        @media screen and (max-width: 1500px) {
          max-width: 16px;
          cursor: pointer !important;
        }
      }
    }

    .displayOption {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #282829;
        margin-bottom: 0;

        @media screen and (max-width: 1500px) {
          font-size: 13px;
          line-height: 18px;
        }
      }

      a {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #312eff;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .similarImg {
      margin-bottom: 20px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 10px;

        .MuiTypography-root {
          font-size: 12px !important;
        }
      }
    }

    .industryWrap {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom: 20px;

      .indTitle {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #282829;
          margin-bottom: 0;

          @media screen and (max-width: 1500px) {
            font-size: 13px;
            line-height: 16px;
          }
        }

        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #312eff;

          @media screen and (max-width: 1500px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .inputWrap {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .inputbox {
          position: relative;
          display: block;
          width: 100%;

          input {
            position: relative;
            display: block;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            border: 1px solid #b3b3b3;
            border-radius: 8px;
            height: 40px;
            padding: 10px;
            outline: none;
          }

          .perIcon {
            position: absolute;
            display: flex;
            width: auto;
            right: 15px;
            top: 0;
            bottom: 0;
            align-items: center;
          }
        }
      }
    }

    .buttongrp {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      button {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.02em;
        border: none;
        background: transparent;
        height: 48px;
        color: #282829;
        padding: 8px 40px;
        width: 100%;
        max-width: 160px;
        border-radius: 25px;
        cursor: pointer;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
          line-height: 16px;
          height: 34px;
          padding: 4px;
        }

        &:last-child {
          background-color: #312eff;
          color: #fff;
        }
      }
    }
  }

  // filter result
  .filterResult {
    position: relative;
    display: grid;
    width: 100%;
    gap: 0.5rem;
    margin-left: auto;

    &.active {
      width: calc(100% - 418px);

      @media screen and (max-width: 1500px) {
        width: calc(100% - 288px);
      }
    }

    &.column4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.column5 {
      grid-template-columns: repeat(5, 1fr);
    }

    &.column6 {
      grid-template-columns: repeat(6, 1fr);
    }

    &.column7 {
      grid-template-columns: repeat(7, 1fr);
    }

    &.column8 {
      grid-template-columns: repeat(8, 1fr);
    }

    .resultBoxWrapper {
      display: contents;
    }

    .nodata {
      text-align: center;

      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #000;
      }
    }

    .resultBox {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: contain;
      justify-content: center;
      background-color: #f3e5dd;
      border-radius: 4px;
      cursor: pointer;
      border: 3px solid transparent;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

      &.selected {
        border-color: #312eff;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 185px;
      }

      .ctr-tag {
        position: absolute;
        display: flex;
        width: 57px;
        align-items: center;
        justify-content: center;
        background: rgba(40, 40, 41, 0.6);
        backdrop-filter: blur(10px);
        border-radius: 38px;
        height: 28px;
        left: 10px;
        top: 10px;
        @media screen and (max-width: 1500px) {
          width: 48px;
          height: 20px;
          top: 5px;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #fff;
          margin-bottom: 0;

          @media screen and (max-width: 1500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }

    .detailsPage {
      grid-column: 1 / -1;

      .detailMain {
        position: relative;
        display: flex;
        width: 100%;
        gap: 30px;

        @media screen and (max-width: 992px) {
          flex-direction: column;
        }

        .detailImg {
          position: relative;
          display: block;
          width: 45%;

          .imgWrap {
            border: 2px solid #e6e6e6;
            padding: 10px;
          }

          @media screen and (max-width: 992px) {
            width: 100%;
            margin-bottom: 20px;
          }
        }

        .detailContent {
          position: relative;
          display: block;
          width: 53%;

          @media screen and (max-width: 992px) {
            width: 100%;
          }

          .detailHeader {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;

            .detailTitle {
              h5 {
                color: #333333;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 0;

                @media screen and (max-width: 1500px) {
                  font-size: 16px;
                  line-height: 18px;
                }
              }
            }

            .detailFeature {
              ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 10px;

                @media screen and (max-width: 1500px) {
                  gap: 4px;

                  svg {
                    max-width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ctrBox {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-color: #f9fafb;
  border-radius: 16px;
  margin-bottom: 20px;
  align-items: center;

  @media screen and (max-width: 1500px) {
    padding: 14px 30px;
    margin-bottom: 10px;
  }

  .formate {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    // border-bottom: 1px solid #e6e6e6;
    // padding-bottom: 20px;
    // margin-bottom: 20px;

    .formateCont {
      h5 {
        color: #282829;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0;
        align-items: center;
      }

      p {
        display: flex;
        color: #282829;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        align-items: center;
        margin-bottom: 0;
        gap: 10px;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }

  .project-title {
    margin-bottom: 10px;

    h5 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0%;
      color: #282829;
    }
  }

  .project-listing {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        width: auto;
        gap: 10px;
        margin-bottom: 10px;

        span {
          display: none;
        }

        &:hover {
          span {
            display: inline-block;
          }
        }

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;
          color: #282829;
          transition: 0.5s all ease;

          &:hover {
            color: #312eff;
            transition: 0.5s all ease;
          }
        }
      }
    }
  }
}

.ctr-details {
  position: relative;
  display: block;
  width: 100%;

  .ctr-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h5 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      color: #282829;
    }
  }

  .ctr-details-content {
    position: relative;
    display: flex;
    width: 100%;

    .ctr-details-box {
      width: 50%;

      .ctr-cont {
        margin-bottom: 20px;

        h5 {
          color: #282829;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0%;
        }

        p {
          color: #282829;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;
          margin-bottom: 0;
          display: flex;
          gap: 10px;

          a {
            color: #282829;

            &:hover {
              color: #312eff;
            }
          }
        }
      }
    }
  }
}

.ai-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1500px) {
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  h5 {
    color: #333333;
    font-family: Noto Sans;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    margin-bottom: 0;
  }
}

.ai-cont {
  p {
    margin-bottom: 0;
    color: #333333;
    font-family: Noto Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.visionBtn {
  a {
    background-color: #312eff;
    color: #fff;
    text-decoration: none;
    height: 48px;
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    max-width: 236px;
  }
}

.project-filter {
  position: relative;
  display: block;
  width: 100%;

  .project-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .project-listing {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;

      @media screen and (max-width: 1500px) {
        gap: 5px;
      }

      li {
        display: flex;
        align-items: center;
        width: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        color: #20218f;
        background-color: #eff3ff;
        border: 1px solid rgba(40, 40, 41, 0.102);
        margin-bottom: 0;
        padding: 5px 10px;
        border-radius: 30px;

        @media screen and (max-width: 1500px) {
          padding: 2px 5px;
          font-size: 12px;
          line-height: 16px;
        }

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;
          color: #282829;
          transition: 0.5s all ease;

          &:hover {
            color: #312eff;
            transition: 0.5s all ease;
          }
        }
      }
    }
  }
}

.buttonGrp {
  position: relative;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-end;

  button {
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    border: none;
    background: transparent;
    height: 48px;
    color: #282829;
    padding: 8px 20px;
    width: 100%;
    max-width: 155px;
    border-radius: 25px;
    cursor: pointer;

    &:last-child {
      background-color: #312eff;
      color: #fff;
    }
  }
}

.addTagWrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin: 20px 0;
  gap: 20px;

  @media screen and (max-width: 1500px) {
    margin-bottom: 0;
  }

  .industryTagBox {
    width: 50%;

    .addTag {
      margin-bottom: 15px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 8px;
      }

      h5 {
        color: #282829;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      ul {
        li {
          padding: 0;
          background-color: transparent;
          border: none;
          color: #282829;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          @media screen and (max-width: 1500px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.chipWrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px;

  @media screen and (max-width: 1500px) {
    padding: 5px 0;
  }

  .filterIcon {
    position: relative;
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

  ul {
    display: flex;
    list-style: none;
    max-width: calc(100% - 190px);
    overflow: auto;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: left;
    gap: 6px;
    padding-bottom: 4px;

    li {
      border: 1px solid rgba(40, 40, 41, 0.1);
      background: #eff3ff;
      position: relative;
      display: flex;
      width: auto;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      border-radius: 25px;
      padding: 6px 12px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #20218f;
      white-space: nowrap;

      @media screen and (max-width: 1500px) {
        font-size: 11px;
        line-height: 14px;
        padding: 3px 6px;
        gap: 4px;
      }
    }
  }

  .searchResult {
    position: absolute;
    display: flex;
    width: 172px;
    background: var(--white);
    right: 0;
    top: 0;
    // padding: 20px;
    align-items: center;
    justify-content: center;
    bottom: 0;
    cursor: default;
    background: #fff;

    p {
      font-size: 14px;
      font-weight: 400;
      color: #282829;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }
}

// Mui default css replaced
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #312eff !important;
  opacity: 1 !important;
}

.css-jsexje-MuiSwitch-thumb {
  background-color: #fff !important;
}

.MuiFormControlLabel-label {
  color: #282829 !important;
  font-size: 14px !important;
  font-family: "Noto Sans" !important;

  @media screen and (max-width: 1500px) {
    font-size: 11px !important;
  }
}

.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}
.autoCompleteDropdown .MuiInputBase-root {
  padding: 0 !important;
}

.css-1x6bjyf-MuiAutocomplete-root .MuiAutocomplete-tag {
  border: 1px solid rgba(40, 40, 41, 0.1) !important;
  border-radius: 60px !important;
  background: rgba(239, 243, 255, 1) !important;
  font-size: 12px;
  height: 25px;
}

.css-18col2x .MuiAutocomplete-tag {
  margin: 3px;
  max-width: calc(100% - 6px);
  font-size: 12px;
  height: 25px;
  background: #eff3ff !important;
  border: 1px solid #2828291a !important;
}

.css-o1spff .MuiChip-deleteIcon {
  font-size: 14px !important;
  color: #20218f !important;
}
.autoCompleteDropdown .MuiAutocomplete-tag {
  border: 1px solid rgba(40, 40, 41, 0.1) !important;
  border-radius: 60px !important;
  background: rgba(239, 243, 255, 1) !important;
  font-size: 12px;
  height: 25px;

  span {
    line-height: normal;
    font-size: 11px;
  }

  svg {
    font-size: 14px;
  }
}

.css-38raov-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  fill: rgba(32, 33, 143, 1) !important;
}

.css-i4bv87-MuiSvgIcon-root {
  //fill: rgba(49, 46, 255, 1) !important;
  border-radius: 4px !important;
}

.checklist {
  margin-bottom: 20px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #282829;
  }

  .hidden-checkbox {
    visibility: hidden;
    position: absolute;
  }
}
.checklist .MuiFormGroup-root {
  position: relative;
  display: flex !important;
  flex-direction: unset !important;
  gap: 6px;
}

.checklist .MuiFormControlLabel-root {
  border: 2px solid #cccccc;
  padding: 4px 12px;
  cursor: pointer;
  font-size: 14px;
  color: #cccccc;
  height: 34px;
  border-radius: 34px;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease;

  @media screen and (max-width: 1500px) {
    padding: 4px 6px;
    height: 28px;
    font-size: 12px;
  }

  .MuiFormControlLabel-root:hover {
    border-color: rgba(49, 46, 255, 0.7);
  }

  .highlight-label {
    border-color: rgba(49, 46, 255, 1);
    background-color: #fff;
    color: #312eff !important;
  }

  .highlight-label .MuiFormControlLabel-label {
    font-size: 14px;
    color: #312eff !important;
  }
}

.industryWrap .checklist .MuiCheckbox-root,
.industryWrap .checklist .MuiRadio-root {
  opacity: 0;
  width: 0;
  margin: 0;
  position: absolute;
}

/* Style for checked state */
.industryWrap .checklist .Mui-checked + .MuiFormControlLabel-label {
  color: #312eff !important;
  font-weight: 500;
}

.industryWrap .checklist .MuiFormControlLabel-root.checked {
  border-color: #312eff !important;
  background-color: transparent;
}

.sliderContainer {
  position: relative;
  padding: 0 40px;

  .custom-prev,
  .custom-next {
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    background: #b3b3b3;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
      background: #f5f5f5;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .custom-prev {
    left: 0;
  }

  .custom-next {
    right: 0;
  }

  // Hide default Swiper navigation arrows
  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      display: none;
    }
  }
}
