.heroProject {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 0;

  @media screen and (max-width: 1500px) {
    padding: 12px 0;
  }

  .titleWrap {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0.02em;
      color: #282829;
      margin-bottom: 0;

      @media screen and (max-width: 1500px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .titleAction {
      position: relative;
      display: flex;
      width: 50%;
      align-items: center;
      gap: 15px;
      justify-content: flex-end;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        width: 174px;
        height: 48px;
        border-radius: 30px;
        background-color: #312eff;
        color: #fff;
        text-decoration: none;
        gap: 6px;
        @media screen and (max-width: 1500px) {
          height: 34px;
          width: 140px;
          font-size: 14px;
        }

        svg {
          width: 22px;
          @media screen and (max-width: 1500px) {
            width: 16px;
          }
        }
      }

      .searchBox {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 48px;
        transition: width 0.5s linear;

        @media screen and (max-width: 1500px) {
          width: 38px;
        }

        input {
          position: relative;
          display: block;
          width: 48px;
          height: 48px;
          border: 2px solid rgba(40, 40, 41, 0.102);
          border-radius: 30px;
          padding: 0px 30px 0px 12px;
          outline: none;
          text-indent: 25px;
          transition: width 0.5s linear;

          @media screen and (max-width: 1500px) {
            width: 34px;
            height: 34px;
            padding: 0 18px 0 6px;
          }
        }

        .searchIcon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          right: 0;
          z-index: 2;
          cursor: pointer;
          transition: right 0.4s linear;
          border-radius: 30px;
          @media screen and (max-width: 1500px) {
            width: 34px;
            height: 34px;
          }

          svg {
            width: 22px;
            @media screen and (max-width: 1500px) {
              width: 18px;
            }
          }
        }

        &.active {
          width: 308px;

          input {
            width: 100%;
            padding: 10px 30px 10px 12px;
            border-color: #312eff;
            transition: right 0.5s linear;
          }

          .searchIcon {
            right: 260px;
            transition: right 0.5s linear;
          }
        }
      }
    }
  }
}

.MuiAutocomplete-option {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #282829;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    background-color: rgba(49, 46, 255, 0.04);
  }

  &[aria-selected="true"] {
    background-color: transparent;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (max-width: 1500px) {
    padding: 6px 16px;
    font-size: 14px;
    line-height: 20px;
  }
}

.tabel_border_bottam {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 700px;
  overflow: auto;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  gap: 15px;
  width: auto;

  @media only screen and (max-width: 992px) {
    display: block;
    margin-top: 15px;
  }
}
