.color-picker-container {
  .color-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }

  .color-display {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }

  .color-picker-popover {
    position: absolute;
    z-index: 2;
  }

  .color-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
