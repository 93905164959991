.standalone-detail-page {
  padding: 20px;
  max-width: 1280px;
  margin: 0 auto;

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;

    h1 {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      color: #333;
    }

    .back-button {
      padding: 8px 15px;
      background: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #333;

      &:hover {
        background: #e5e5e5;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    .loading-spinner {
      width: 50px;
      height: 50px;
      border: 3px solid #f3f3f3;
      border-top: 3px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      margin-bottom: 20px;
    }

    p {
      color: #666;
      font-size: 16px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .error-container {
    text-align: center;
    padding: 40px 0;

    p {
      color: #d32f2f;
      font-size: 16px;
      margin-bottom: 20px;
    }

    button {
      padding: 10px 20px;
      background: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #e5e5e5;
      }
    }
  }
}

@media (max-width: 768px) {
  .standalone-detail-page {
    padding: 15px;

    .page-header {
      margin-bottom: 15px;

      h1 {
        font-size: 20px;
      }
    }
  }
}
