.min-w-170 {
  min-width: 170px;
}

.new-header-logo {
  max-width: 270px;
  max-height: 40px;

  @media (max-width: 576px) {
    max-width: 130px;
  }
}

.select-logo-width {
  @media (max-width: 576px) {
    max-width: 25px;
  }
}

.translate-image-width {
  @media (max-width: 576px) {
    min-width: 140px;
  }
}

.mico-logo-width {
  max-width: 270px;
  height: 40px;

  @media (max-width: 1024px) {
    max-width: 170px;
    height: 30px;
  }

  @media (max-width: 900px) {
    max-width: 140px;
    height: 25px;
  }

  @media (max-width: 576px) {
    max-width: 170px;
    height: 30px;
  }
}

.logo-align {
  @media (max-width: 900px) {
    justify-content: start !important;
  }

  @media (max-width: 576px) {
    justify-content: center !important;
  }
}

.input-icon-width {
  @media (max-width: 576px) {
    max-width: 30px;
  }
}

.shadow-notification {
  box-shadow:
    rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
