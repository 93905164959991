@import "./component/variables.scss";

.multiselect-user .MuiButtonBase-root {
  background-color: #e6e6e6 !important;
  border: none !important;
  border-radius: 18px !important;
  height: 24px !important;

  &:last-child {
    background-color: transparent !important;
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  border: none !important;
}

.multiselect-user {
  .css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
    //padding: 0px 9px !important;
    padding: 0.5px 4px 7.5px 5px !important;
  }

  .css-2dvya8 .MuiAutocomplete-inputRoot {
    padding: 0 9px !important;
  }
}

.multiselect-user
  .MuiButtonBase-root
  .MuiIconButton-root
  .MuiIconButton-sizeMedium
  .MuiAutocomplete-clearIndicator
  .css-edpqz1 {
  display: none !important;
  visibility: hidden !important;
}

.multiselect-user
  .MuiButtonBase-root
  .MuiIconButton-root
  .MuiIconButton-sizeMedium
  .MuiAutocomplete-clearIndicator
  .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
  visibility: hidden !important;
}

.sticky_top {
  border-bottom: 1px solid #e6e6e6;
}

.tabel-fild-title {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  border-bottom: 2px solid #cccccc !important;
  position: sticky !important;
  white-space: nowrap;
}

.tabel-fild {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 400;
  vertical-align: middle;
  white-space: nowrap;
}

.tabel-fild {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}

.project_name {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
  white-space: nowrap;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}

.project_des {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--text-black);
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}

.search_width {
  width: 100%;

  @media (min-width: 1024px) {
    width: 75% !important;
  }

  @media (min-width: 768px) {
    width: 75% !important;
  }

  input {
    height: 56px;

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  img {
    position: absolute;
    top: 8px;
    right: 10px;

    @media (max-width: 768px) {
      max-width: 30px;
      top: 5px;
    }
  }
}

.tabel-fild span {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 8px;
  border-radius: 10px;

  @media screen and (max-width: 1500px) {
    font-size: 11px;
  }
}

.select_channel {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  @media screen and (max-width: 1500px) {
    font-size: 12px;
  }
}

.from-input {
  border: 1px solid var(--border-color) !important;
  border-radius: 8px !important;
  min-height: 40px;
  font-size: var(--fs-16);
  line-height: 24px;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  cursor: pointer;
}

.from-input-link {
  border: 1px solid var(--border-color) !important;
  border-radius: 8px !important;
  min-height: 40px;
  font-size: var(--fs-16);
  line-height: 24px;
  font-weight: 400;
  align-items: center;
  font-family: "Noto Sans", sans-serif;
  // min-width: -webkit-fill-available;
  margin-top: 10px;
  flex: 1;
  box-sizing: border-box;
  justify-content: center;
}

.from-input2 {
  border: 1px solid var(--border-color) !important;
  border-radius: 8px !important;
  // min-height: 40px;
  font-size: var(--fs-16);
  line-height: 24px;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
}

::placeholder {
  font-size: var(--fs-14);
  font-weight: 400;
  line-height: 20px;
  font-family: "Noto Sans", sans-serif;
  color: #808080 !important;
}

.form-control:focus,
.btn:focus {
  box-shadow: none !important;
}

/* Remove autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #000 !important;
  caret-color: auto !important;
  background-color: var(--color-white) !important;
}

.tabel_border_bottam .table > :not(:last-child) > :last-child > * {
  border-bottom: none;
}

.project-form-error {
  font-size: var(--fs-12);
  color: red;
  margin-bottom: 5px;
}

.text-green {
  color: green !important;
}

/* From Uiverse.io by abrahamcalsin */
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.multiselect-user
  .MuiPaper-root
  .MuiPaper-elevation
  .MuiPaper-rounded
  .MuiPaper-elevation8
  .MuiPopover-paper
  .MuiMenu-paper
  .MuiMenu-paper
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-width: 466px !important;
  min-width: 466px !important;
  max-height: 466px !important;
  min-height: 466px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.input-date-icon {
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
}

.date-input {
  .p-inputswitch {
    height: 21px !important;
    width: 40px !important;
  }

  .p-inputswitch.p-highlight .p-inputswitch-slider {
    background-color: #08711d !important;
  }

  .p-inputswitch.p-highlight .p-inputswitch-slider:before {
    background-color: white !important;
    left: 0px !important;
    top: 64% !important;
  }

  .p-inputswitch .p-inputswitch-slider {
    background-color: transparent !important;
    border: 1px solid #b3b3b3 !important;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background-color: #b3b3b3;
    left: 2px !important;
    height: 15px !important;
    width: 15px !important;
    top: 63% !important;
  }

  .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight
    .p-inputswitch-slider {
    background: #08711d;
  }
}

.check-popover {
  background-color: red;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rounded-25 {
  border-radius: 25px !important;
}

.custom-placeholder::placeholder {
  font-size: 14px;
  /* Adjust font size */
  color: #007bff;
  /* Adjust font color */
  font-weight: bold;
  /* Adjust font weight */
}

.swich-popup {
  position: absolute;
  left: -520px;
  bottom: 150px;
  background-color: white;
  max-width: 480px;

  @media (max-width: 1560px) {
    max-width: 380px;
    left: -420px;
  }

  @media (max-width: 1360px) {
    max-width: 320px;
    left: -350px;
  }

  @media (max-width: 1360px) {
    max-width: 260px;
    left: -280px;
  }

  @media (max-width: 1060px) {
    display: none;
  }
}

.swich-popup-curve {
  position: relative;

  &::after {
    content: "";
    height: 14px;
    width: 15px;
    position: absolute;
    right: -23px;
    background-color: white;
    top: 40%;
    rotate: 134deg;
  }
}

.comment-task-sidebar {
  @media (max-width: 992px) {
    position: absolute;
    max-width: 300px !important;
    right: 0px;
    // display: none;
    height: calc(100vh - 85px) !important;
    // overflow-y: auto;
  }
}

.miro-tab-button {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border: 2px solid #000;
  padding: 3px;

  @media (max-width: 576px) {
    margin: 0 0;
  }
}

.pagination-top {
  position: absolute;

  @media (max-width: 1024px) {
    position: inherit;
  }
}

// New Project UI

.hero-project {
  background-color: #f9fafb;
  margin: 25px 0;

  @media screen and (max-width: 1500px) {
    margin: 20px 0;
  }

  .dash-title {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;

    h2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      color: rgba(20, 19, 83, 1);

      @media screen and (max-width: 1500px) {
        font-size: 18px;
        margin-bottom: 0;
      }
    }

    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(49, 46, 255, 1);
      text-decoration: none;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .cardWrapper {
    position: relative;
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 10px;
    scroll-behavior: smooth;
  }

  .projectWrap {
    position: relative;
    display: block;
    width: 394px;
    padding: 25px;
    background: #fff;
    border-radius: 6px;
    flex-shrink: 0;
    transition: 0.5s all ease;
    margin-top: 30px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.11);
    }

    @media screen and (max-width: 1500px) {
      margin-top: 15px;
      width: 290px;
      padding: 20px;
    }

    @media screen and (max-width: 992px) {
      padding: 15px;
      width: 290px;
    }

    .projectImg {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 25px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 5px;
      }

      svg {
        max-width: 50px;

        @media screen and (max-width: 1500px) {
          max-width: 37px;
          max-height: 37px;
        }
      }

      p {
        position: relative;
        display: inline-block;
        width: auto;
        padding: 2px 8px;
        background: #f1d2b0;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        margin-bottom: 0;
      }
    }

    .projectContent {
      margin-bottom: 20px;

      @media screen and (max-width: 1500px) {
        margin-bottom: 5px;
      }

      h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 5px;
        color: #282829;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
          font-size: 14px;
          margin: 0;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #282829;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;

        @media screen and (max-width: 1500px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .projectFooter {
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      @media screen and (max-width: 1500px) {
        gap: 10px;
        margin-bottom: 10px;
      }

      .projectFooterLeft {
        display: inline-block;
        width: 45%;

        h5 {
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #808080;
          margin-bottom: 4px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #282829;
          margin-bottom: 0;

          @media screen and (max-width: 1500px) {
            font-size: 12px;
          }

          .circle {
            position: relative;
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #4f6bed;
            color: #fff;
            font-size: 14px;
            font-weight: 600;

            @media screen and (max-width: 1500px) {
              width: 28px;
              height: 28px;
              font-size: 9px;
            }
          }
        }
      }
    }

    .progressbar {
      position: relative;
      display: block;
      width: 100%;
      height: 7px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #e9ecef;

      .inlineProgress {
        position: absolute;
        display: block;
        width: 50%;
        height: 100%;
        background: rgba(241, 210, 176, 1);
      }
    }

    .due-date {
      margin-top: 8px;

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: rgba(160, 18, 0, 1);
        margin-bottom: 0;
        text-transform: uppercase;
        margin-top: 10px;

        @media screen and (max-width: 1500px) {
          font-size: 10px;
          line-height: 12px;
          margin-top: 5px;
        }
      }
    }
  }

  .visionWrap {
    position: relative;
    display: flex;
    width: 100%;
    gap: 51px;
  }
}

// table csss
.project_name_div {
  position: relative;
  display: flex;
  width: auto;
  align-items: center;
  gap: 10px;

  .projectIcon {
    width: 50px;

    svg {
      max-width: 50px;
    }
  }

  .projectCont {
    display: inline-block;
    width: auto;

    .name {
      display: block;
      width: 100%;
      max-width: 170px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #282829;
      margin-bottom: 7px;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }

      .highlight {
        background-color: #f1d2b0;
        margin: 0;
        padding: 4px;
        border-radius: 50px;
      }
    }

    .description {
      display: block;
      width: 100%;
      max-width: 170px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #666666;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }
  }
}

.project-table-wrapper {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;

  thead {
    th {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: #666666;
      padding: 14px 8px;
      white-space: nowrap;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }
  }

  tbody {
    td {
      vertical-align: middle;
    }
  }

  .project-status {
    position: relative;
    display: flex;
    width: auto;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2px;

    @media screen and (max-width: 1500px) {
      font-size: 12px;
    }
  }

  .current-timeline {
    display: block;
    width: auto;

    .current-title {
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #282829;
      margin-bottom: 2px;

      @media screen and (max-width: 1500px) {
        font-size: 12px;
      }
    }

    .current-date {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      @media screen and (max-width: 1500px) {
        font-size: 11px;
      }
    }
  }
}

.filterTabs {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 20px 0;

  @media screen and (max-width: 1500px) {
    padding: 10px 0;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;

    li {
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #b3b3b3;
        padding: 6px 18px;
        border-radius: 23px;
        text-decoration: none;
        color: #282829;
        transition: 0.5s all ease;
        @media screen and (max-width: 1500px) {
          font-size: 12px;
        }

        &.active {
          border-color: #312eff;
          color: #312eff;
          transition: 0.5s all ease;
        }
      }
    }
  }

  .projectTab {
    .MuiSelect-select {
      // padding: 0 !important;
      width: 121px;
      min-width: fit-content;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      border: 2px solid #b3b3b3;
      height: 30px;
      border-radius: 30px;
      // padding-left: 15px !important;
      color: #282829 !important;
      font-family: "Noto Sans";
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      @media screen and (max-width: 1500px) {
        font-size: 12px;
        width: 100px;
      }
    }
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center;
  gap: 30px;

  p {
    color: #666666;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pageIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
