.channelName:hover {
  color: #312eff;
}

.channelName {
  cursor: auto;
}

.tooltipChannel {
  background: #20218f;
  color: #fff;
}

.gotItButton {
  background: #312eff;
}

.exportBtn {
  height: 40px;
  width: auto;
  border-radius: 20px;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-inline: 20px;
  border: none;
  margin-right: 2px;
}

.activeExport {
  background-color: #312eff;
  color: #fff;
}

.disabledExport {
  background-color: #b8b8b8;
  color: #4f4f4f;
}

.exportImageItem {
  border-radius: 20px;
}

.exportImageUnselectedItem {
  border: none;
}
