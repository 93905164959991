.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #ccc;
  padding-block: 25px;
}

.fieldContainer:last-child {
  border-bottom: 1px solid #ccc;
}

.fieldContainer p {
  margin: 0;
}

.formLabel {
  font-weight: 700;
  font-size: 14px;
  margin-left: 0;
  letter-spacing: 1px;
}

.formText {
  color: #6f6f6f;
}

.changeButton {
  background: transparent;
  padding: 0px;
  color: #312eff !important;
  font-weight: 600;
  border: none;
  text-decoration: underline;
  border: none !important;
}

.firstContainer {
  border-top: none;
}

/* YourStyles.module.css or YourStyles.css */
.fieldContainer {
  /*... other styles */
  position: relative;
  /* Needed for absolute positioning of dropdown */
}

.changeButton {
  /*... other styles */
}

.dropdown {
  position: absolute;
  /* Position the dropdown */
  top: 100%;
  /* Below the button */
  left: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  /* Bootstrap border color */
  border-radius: 4px;
  z-index: 1000;
  /* Ensure it's on top */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional shadow */
}

.dropdownItem {
  display: block;
  /* Make items stack vertically */
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  /* Bootstrap text color */
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f8f9fa;
  /* Light gray on hover */
}

.dropdownItem.selected {
  background-color: lightblue;
}

.valid {
  color: #1a683b;
}

.invalid {
  color: #000;
}

.passwordContainer {
  position: relative;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 10px;
  margin-top: 7px;
}

.passwordField {
  outline: none;
  border: none;
  width: 100%;
}

.passwordButton {
  width: max-content;
  padding: 5px 20px;
  border-radius: 100px;
  background: #312eff;
  color: white;
  font-weight: 600;
  margin-top: 10px;
  border: none !important;
}

.passwordButton:disabled {
  background: #5b5b5b;
}

.capitalize {
  text-transform: capitalize;
}

.profileImage {
  aspect-ratio: 1;
}

.profileImageContainer {
  position: relative;
  width: fit-content;
}

.uploadImageIcon {
  background: #4431f9;
  border-radius: 1000px;
  padding: 5px;
  border: 1px solid #fff;
  width: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.dropdownLabel {
  padding: 10px 15px;
  border: 1px solid;
  border-radius: 20px;
  display: block;
  position: relative;
  width: 100%;
}

.dropdownContainer {
  position: relative;
  min-width: max-content;
  width: 50%;
}

.dropdownIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* .dropdownField {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 15px 10px;
  border: 1px solid;
  max-height: 200px;
  overflow: auto;
  z-index: 100;
  background: white;
  border-radius: 20px;
  cursor: pointer;
} */
.dropdownField {
  position: absolute;
  margin-top: 0.5px;
  top: 100%;
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow: auto;
  z-index: 100;
  background: #fff;
  border-radius: 8px;
  box-shadow:
    0px 10px 10px -5px rgba(0, 0, 0, 0.04),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;

  li {
    display: block;
    background-color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #282829;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      background-color: #eff3ff;
      transition: all 0.5s ease;
    }
  }
}

.channelItem {
  padding: 5px;
}

.channelItem:hover {
  background: #eff3ff;
}
