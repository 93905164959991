.tabs-conatainer {
  min-width: 290px;
  max-width: 290px;
  animation: sidebar_animation ease-in-out 0.6s;
  transform: translateX(0px);
  height: calc(100vh - 56px);
  overflow-y: auto;
  position: absolute;
  left: 0px;
  z-index: 50;

  @media (max-width: 768px) {
    min-width: 220px;
    max-width: 220px;
    height: calc(100vh - 65px);
  }

  @media (max-width: 524px) {
    height: calc(100vh - 88px);
  }

  @keyframes sidebar_animation {
    0% {
      transform: translateX(-200px);
    }

    100% {
      transform: translateX(0px);
    }
  }
}

.tabs-search {
  input {
    padding-left: 30px !important;
    height: 40px;
    border-radius: 8px !important;
    border: 1px solid #b3b3b3 !important;
    font-size: 14px !important;

    @media (max-width: 768px) {
      height: 32px;
    }
  }

  img {
    left: 2px;
    top: 5px;
    max-width: 30px;
    // filter: brightness(4);

    @media (max-width: 768px) {
      top: 2px;
    }
  }
}

.tabs-grid-image1 {
  display: grid;
  // grid-template-columns: auto auto;
  gap: 10px;

  .tabs-image {
    max-width: 120px;
    height: 100px;
    width: 100%;

    @media (max-width: 768px) {
      height: 70px;
    }

    img {
      // width: 100%;
      object-fit: contain;
      height: -webkit-fill-available;
    }
  }
}

.micro_ad {
  font-size: var(--fs-14);
  font-weight: 600;
  line-height: 20px;
  // max-width: 50px;

  @media (max-width: 992px) {
    font-size: 12px;
    //   max-width: 50px;
  }
}

.modalWrapper {
  position: relative;
  width: 100%;
  display: block;

  &.active {
    display: flex;
    gap: 30px;

    .modalSearchWrapper {
      display: block;
      width: 65%;
    }

    .modalDetailsWrapper {
      width: 35%;
    }
    .heroResult {
      width: 63%;
    }
  }

  .modalSearchWrapper {
    display: block;
    width: 100%;
  }
}

.tabWrapper {
  position: relative;
  display: block;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    height: auto;
    border-bottom: 2px solid #e6e6e6;

    li {
      font-family: Noto Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      height: 30px;

      a {
        color: #282829;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: 0.5s allease;
        border-radius: 0px;
        padding: 10px;
        margin-right: 10px;

        &.active,
        &:hover {
          color: #312eff;
          border-color: #312eff;
          transition: 0.5s all ease;
        }
      }
    }
  }
}

.imageDetailsSlider {
  position: relative;
  .detail-swiper {
    padding-bottom: 40px;

    .swiper-button-prev,
    .swiper-button-next {
      color: #6e82ff;

      &:after {
        font-size: 24px;
      }

      &.swiper-button-disabled {
        opacity: 0.35;
      }
    }

    .swiper-pagination {
      bottom: 10px;

      .swiper-pagination-bullet {
        background: #6e82ff;
        opacity: 0.5;

        &-active {
          opacity: 1;
        }
      }
    }
  }

  .detailImg {
    background-color: #f5f5f5;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px solid #e6e6e6;
  }
}
